import { ArrowBack } from '@mui/icons-material';

import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';

import { useEffect } from 'react';

import { useParams } from 'react-router';

import { ManageRoleAccess } from '@features/manage-role-access';

import { ResetMFAButton } from '@features/reset-mfa';

import { UserAttributes } from '@entities/user';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import {
  $directories,
  $user,
  $userGroups,
  backClicked,
  getUserByEmailFx,
  page
} from './model';

const UserPage = createView()
  .props({
    user: $user,
    userGroups: $userGroups,
    pageMounted: page.mounted,
    directories: $directories,
    loading: getUserByEmailFx.pending,
    onBackClick: backClicked.prepend(trimPayload)
  })
  .exit(page.unmounted)
  .map(({ pageMounted }) => {
    const params = useParams<{ email: string }>();

    useEffect(() => {
      pageMounted(params.email);
    }, [params.email]);

    return {};
  })
  .view(({ loading, user, onBackClick }) => {
    if (loading) {
      return (
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          width='100%'
          flex={1}
        >
          <CircularProgress />
        </Grid>
      );
    }

    if (!user) {
      return (
        <Grid>
          <Typography>User not found</Typography>
        </Grid>
      );
    }

    return (
      <Grid container flexDirection='column' gap={2} flex={1}>
        <Grid alignSelf='flex-start'>
          <IconButton onClick={onBackClick}>
            <ArrowBack />
          </IconButton>
        </Grid>

        <UserAttributes
          firstName={user.attributes.givenName}
          lastName={user.attributes.familyName}
          email={user.attributes.email}
        >
          <ResetMFAButton />
        </UserAttributes>

        <ManageRoleAccess />
      </Grid>
    );
  });

export { UserPage };
