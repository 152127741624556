import type { FormEvent, PropsWithChildren, ReactNode } from 'react';

import type { EnrichedForm } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

type FormProps = PropsWithChildren<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  use: EnrichedForm<any>;

  useInitializedForm?: boolean;

  renderForm?: (
    props: PropsWithChildren<{
      onSubmit: (event?: FormEvent<HTMLFormElement>) => void;
      onReset: (event?: FormEvent<HTMLFormElement>) => void;
    }>
  ) => ReactNode;
}>;

const FormWrapper = createView<FormProps>()
  .map(({ use: form, useInitializedForm }) => ({
    onSubmit: (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      form.submit();
    },

    onReset: (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      if (useInitializedForm) {
        form.resetClicked();
      } else {
        form.reset();
      }
    }
  }))
  .view(
    ({
      children,

      onSubmit,
      onReset,

      renderForm = ({ onSubmit, onReset, children }) => (
        <form onSubmit={onSubmit} onReset={onReset}>
          {children}
        </form>
      )
    }) => <>{renderForm({ onSubmit, onReset, children })}</>
  );

export { FormWrapper as Form };
