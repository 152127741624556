import { Card, CardHeader, Grid } from '@mui/material';

import type { PropsWithChildren } from 'react';

import { createView } from '@shared/lib/view';

type UserAttributesProps = PropsWithChildren<{
  firstName: string;
  lastName: string;

  email: string;
}>;

const UserAttributes = createView<UserAttributesProps>().view(
  ({ firstName, lastName, email, children }) => (
    <Grid>
      <Card>
        <CardHeader
          title={`${firstName} ${lastName}`}
          subheader={email}
          action={children}
          sx={{ '& .MuiCardHeader-action': { alignSelf: 'center' } }}
        />
      </Card>
    </Grid>
  )
);

export { UserAttributes };
