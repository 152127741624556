import { Role } from './types';

const userGroupsPriority = [
  Role.SuperAdmin,
  Role.Admin,
  Role.WriterFull,
  Role.ReaderFull
];

export { userGroupsPriority };
