import { combine, createEvent, restore, sample } from 'effector';

import type { DirectoryContentRestriction } from '@features/manage-role-access';

import { $$manageRoleAccess } from '@features/manage-role-access';

import { $$resetMFA } from '@features/reset-mfa';

import { Role, sortRoles } from '@entities/user';

import type { CustomAttributesDto } from '@shared/api';

import { routes } from '@shared/config';

import { $$router } from '@shared/history';

import { group } from '@shared/lib/effector-group';

import { createPage } from '@shared/lib/units';

import { getDirectoriesFx, getUserByEmailFx } from './api';

import { Adapter } from './lib';

const page = createPage<string>();

const backClicked = createEvent();

const $user = restore(
  getUserByEmailFx.doneData.map(({ data }) => {
    const customAttributes: Required<CustomAttributesDto> = {
      functionsRead: data.customAttributes.functionsRead ?? [],
      functionsWrite: data.customAttributes.functionsWrite ?? [],
      marketsRead: data.customAttributes.marketsRead ?? [],
      marketsWrite: data.customAttributes.marketsWrite ?? []
    };

    return Object.assign(data, { customAttributes });
  }),
  null
);

const $directories = restore(
  getDirectoriesFx.doneData.map(({ data }) => data),
  { function: [], market: [] }
);

const $userGroups = $user.map(user => {
  if (user) {
    const groups = sortRoles(user.groups.filter(Boolean) as Role[]);

    return groups.length ? groups : [Role.Custom];
  }

  return [Role.Custom];
});

const $contentRestrictions = combine(
  $user,
  $directories,
  (
    user,
    directories
  ): {
    function: DirectoryContentRestriction[];
    market: DirectoryContentRestriction[];
  } => {
    if (!user)
      return {
        function: [],
        market: []
      };

    const {
      functionsRead = [],
      functionsWrite = [],
      marketsRead = [],
      marketsWrite = []
    } = user.customAttributes;

    return {
      function: Adapter.toDirectoryContentRestriction(directories.function, {
        read: functionsRead,
        write: functionsWrite
      }),
      market: Adapter.toDirectoryContentRestriction(directories.market, {
        read: marketsRead,
        write: marketsWrite
      })
    };
  }
);

group('load', () => {
  sample({
    clock: page.mounted,
    target: [getUserByEmailFx, getDirectoriesFx]
  });

  sample({
    source: {
      contentRestrictions: $contentRestrictions,
      userGroups: $userGroups
    },
    fn: ({ contentRestrictions, userGroups }) => ({
      role: userGroups[0],
      functionAccess: contentRestrictions.function,
      marketAccess: contentRestrictions.market
    }),
    target: $$manageRoleAccess.form.initialize
  });

  sample({
    source: $user,
    filter: Boolean,
    fn: ({ attributes }) => attributes.email,
    target: [$$manageRoleAccess.$email, $$resetMFA.$email]
  });
  sample({
    source: $user,
    filter: Boolean,
    fn: ({ attributes }) => attributes.familyName,
    target: $$manageRoleAccess.$lastName
  });
  sample({
    source: $user,
    filter: Boolean,
    fn: ({ attributes }) => attributes.givenName,
    target: $$manageRoleAccess.$firstName
  });
  sample({
    clock: $user,
    filter: Boolean,
    fn: ({ groups }) => groups,
    target: $$manageRoleAccess.$groups
  });
  sample({
    source: $user,
    filter: Boolean,
    fn: ({ UserStatus }) => UserStatus ?? '',
    target: $$resetMFA.$status
  });

  sample({
    clock: $$manageRoleAccess.dataUpdated,
    source: page.mounted,
    filter: page.$isMounted,
    target: [getUserByEmailFx, getDirectoriesFx]
  });
});

group('back', () => {
  sample({
    clock: backClicked,
    target: $$router.pushFx.prepend(() => routes.users.home())
  });
});

export {
  page,
  backClicked,
  $user,
  $userGroups,
  $directories,
  $contentRestrictions,
  getUserByEmailFx
};
