import { Grid } from '@mui/material';

import type { PropsWithChildren } from 'react';

import { createView } from '@shared/lib/view';

const MainLayout = createView<PropsWithChildren>().view(({ children }) => (
  <Grid container flexDirection='column' gap={2} flex={1} px={5} py={3}>
    {children}
  </Grid>
));

export { MainLayout };
