import { Role } from '@entities/user';

enum ContentRestrictionStatus {
  User = 'User',
  Applied = 'Applied',
  Allowed = 'Allowed',
  Pending = 'Pending'
}

const roles = [
  Role.SuperAdmin,
  Role.Admin,
  Role.WriterFull,
  Role.ReaderFull,
  Role.Custom
];

const userGroupsToAdd = [
  Role.SuperAdmin,
  Role.Admin,
  Role.WriterFull,
  Role.ReaderFull
];

const rolesAllContentAllowed = [Role.SuperAdmin, Role.Admin, Role.WriterFull];

export {
  roles,
  rolesAllContentAllowed,
  ContentRestrictionStatus,
  userGroupsToAdd
};
