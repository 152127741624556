import { sample } from 'effector';

import { $$signUpPage } from '@pages/auth/sign-up';

import { $$confirmEmail } from '@features/auth/confirm-email';

import { $$login, isUserNotConfirmedError } from '@features/auth/login';

import { $$register } from '@features/auth/register';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

import { group } from '@shared/lib/effector-group';

group('success register', () => {
  sample({
    clock: [$$register.signUpFx.done, $$register.resendEmailCodeFx.done],
    target: pushFx.prepend(() => routes.auth.signUp.confirmEmail())
  });

  sample({
    clock: [$$register.signUpFx.done, $$register.resendEmailCodeFx.done],
    source: $$register.form.$values,
    fn: ({ email }) => email,
    target: $$confirmEmail.emailPopulated
  });
});

group('UserNotConfirmedException', () => {
  const notConfirmedUserTryLogged = sample({
    clock: $$login.loginFx.failData,
    filter: isUserNotConfirmedError
  });

  sample({
    clock: notConfirmedUserTryLogged,
    target: pushFx.prepend(() => routes.auth.signUp.confirmEmailAfterTimeout())
  });

  sample({
    clock: notConfirmedUserTryLogged,
    source: $$login.loginFx.fail,
    fn: ({ params }) => params.email,
    target: $$confirmEmail.emailPopulated
  });
});

group('confirm email', () => {
  sample({
    clock: $$confirmEmail.confirmEmailFx,
    source: $$register.form.$values,
    fn: ({ password }, { email }) => ({ email, password }),
    target: $$login.form.setForm
  });

  sample({
    clock: $$confirmEmail.confirmEmailFx.doneData,
    target: pushFx.prepend(() => routes.auth.login())
  });
});

group('reset', () => {
  sample({
    clock: $$signUpPage.leave,
    target: [$$register.reset, $$confirmEmail.reset]
  });
});
