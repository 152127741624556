import type { Event } from 'effector';

import { createDomain, createEvent, sample } from 'effector';

import type { Form, FormConfig } from 'effector-forms';

import { createForm } from 'effector-forms';

import { typedObjectEntries } from '@shared/lib/prototype';

type EnrichedFormConfig<T> = FormConfig<T> & {
  resetOn?: Event<void>;

  blurValidatePreset?: boolean;
};

type EnrichedForm<T> = Form<T> & {
  initialize: Event<T>;

  resetClicked: Event<void>;
};

const createPatchedForm = <T>({
  resetOn,
  blurValidatePreset,
  ...config
}: EnrichedFormConfig<T>): EnrichedForm<T> => {
  const touched = createDomain();

  typedObjectEntries(config.fields).forEach(([_, field]) => {
    if (!field.units) {
      field.units = {};
    }

    field.units.$isTouched = touched.createStore(false);

    if (blurValidatePreset) {
      field.validateOn = ['blur'];
    }
  });

  if (blurValidatePreset) {
    config.validateOn = ['submit'];
  }

  const form = createForm(config) as EnrichedForm<T>;

  touched.onCreateStore(store => store.on(form.submit, () => true));

  if (resetOn) {
    sample({
      clock: resetOn,
      target: form.reset
    });
  }

  form.initialize = createEvent<T>();
  form.resetClicked = createEvent();

  sample({
    clock: form.initialize,
    target: form.setForm
  });

  sample({
    clock: form.resetClicked,
    source: form.initialize,
    target: form.setForm
  });

  return form;
};

export { createPatchedForm };

export type { EnrichedForm };
