import type { Role } from '@entities/user';

import { sortRoles } from '@entities/user';

import type { UserDto } from '@shared/api';

import type { TableUser } from '../types';

class UserAdapter {
  public static readonly toTableUser = (userDto: UserDto): TableUser => ({
    id: userDto.attributes.sub,
    email: userDto.attributes.email,
    firstName: userDto.attributes.givenName,
    lastName: userDto.attributes.familyName,
    role: userDto.groups.length
      ? sortRoles(userDto.groups as Role[]).join(', ')
      : '-',
    emailConfirmed: userDto.UserStatus === 'CONFIRMED',
    mfaEnabled: !!userDto.Enabled
  });
}

export { UserAdapter };
