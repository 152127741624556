import { restore } from 'effector';

import { meFx } from '@entities/session/api';

import { Role } from '@entities/user';

const $user = restore(meFx, null);

const $isAuthenticated = $user.map(Boolean);

const $canViewUI = $user.map(user => {
  if (!user) return false;

  return (
    user.data.groups.includes(Role.SuperAdmin) ||
    user.data.groups.includes(Role.Admin)
  );
});

const $viewer = $user.map(user => {
  if (!user) return '';

  return `${user.data.firstName} ${user.data.lastName}`;
});

export { $viewer, $isAuthenticated, $user, $canViewUI };
