import { attach } from 'effector';

import { api } from '@shared/api';

import { trimPayload } from '@shared/lib/prototype';

const getUserByEmailFx = attach({ effect: api.users.getUserByEmail });

const getDirectoriesFx = attach({
  mapParams: trimPayload,
  effect: api.directories.getDirectories
});

export { getUserByEmailFx, getDirectoriesFx };
