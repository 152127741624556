import type { TabsProps } from '@mui/material';

import { Tab, Tabs } from '@mui/material';

import type { SyntheticEvent } from 'react';

import { $pathname, pushFx } from '@shared/history';

import { createView } from '@shared/lib/view';

type TabsRouterTab = { label: string; route: string };
type TabsRouterProps = Partial<Omit<TabsProps, 'value' | 'onChange'>> & {
  tabs: TabsRouterTab[];
};

const TabsRouter = createView<TabsRouterProps>()
  .props({ tab: $pathname, tabChanged: pushFx })
  .defaultProps({ variant: 'fullWidth' })
  .map(({ tab, tabs, tabChanged }) => {
    const value = tabs.findIndex(({ route }) => tab.includes(route));

    return {
      value: value === -1 ? 0 : value,
      onChange: (_: SyntheticEvent, index: number) => {
        const tab = tabs[index];
        if (!tab) return;

        tabChanged(tab.route);
      }
    };
  })
  .view(({ tab, value, onChange, tabChanged, tabs, ...props }) => (
    <Tabs value={value} onChange={onChange} {...props}>
      {tabs.map(({ label, route }, index) => (
        <Tab key={route} label={label} value={index} />
      ))}
    </Tabs>
  ));

export { TabsRouter };

export type { TabsRouterProps, TabsRouterTab };
