import { Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import { LoginForm } from '@features/auth/login';

import { routes } from '@shared/config';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

import { registerLinkClicked } from './model';

const LoginPage = createView()
  .props({ onLinkClick: registerLinkClicked.prepend(trimPayload) })
  .view(({ onLinkClick }) => (
    <PageCenterLayout>
      <LoginForm>
        <Link
          component={RouterLink}
          to={routes.auth.signUp.register()}
          onClick={onLinkClick}
        >
          Register
        </Link>
      </LoginForm>
    </PageCenterLayout>
  ));

export { LoginPage };
