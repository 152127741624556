import type { FC } from 'react';

import '@processes/sign-up';

import '@processes/login';

import { mounted } from '@processes/ensure-start-on-login';

import { Pages } from '@pages';

import { MainLayout } from '@widgets/layouts/main-layout';

import { createView } from '@shared/lib/view';

import { Provider } from './provider';

const App: FC = createView()
  .enter(mounted)
  .view(() => (
    <Provider>
      <MainLayout>
        <Pages />
      </MainLayout>
    </Provider>
  ));

export { App };
