import { LoadingButton } from '@mui/lab';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Tabs,
  TextField
} from '@mui/material';

import type { GridColumns } from '@mui/x-data-grid';

import { DataGrid } from '@mui/x-data-grid';

import { useField } from 'effector-forms';

import { some } from 'patronum';

import { useId } from 'react';

import { Role } from '@entities/user';

import { Form } from '@shared/form';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { ContentRestrictionStatus, roles } from './config';

import {
  $canApplyAdminRolesEdit,
  $canEdit,
  $contentAllowedStatus,
  $currentTab,
  $editing,
  $isFunctionTab,
  $submitting,
  editFinished,
  editStarted,
  form,
  rowEdited,
  tabChanged
} from './model';

import type { DirectoryContentRestriction } from './types';

const ManageRoleAccess = createView()
  .props({
    isRestrictionApplied: $contentAllowedStatus.map(
      role => role === ContentRestrictionStatus.Applied
    ),

    editable: $canEdit
  })
  .view(({ isRestrictionApplied, editable }) => (
    <Grid container flexDirection='column' flex={1} component={Card}>
      <Form
        use={form}
        useInitializedForm
        renderForm={({ onReset, children, onSubmit }) => (
          <Grid
            container
            flexDirection='column'
            flex={1}
            component='form'
            onSubmit={onSubmit}
            onReset={onReset}
          >
            {children}
          </Grid>
        )}
      >
        <ContentManagementStatus />

        <Grid container flexDirection='column' component={CardContent}>
          <RoleSelect />
        </Grid>

        <Grid
          container
          flexDirection='column'
          component={CardContent}
          flex={1}
          sx={{ opacity: isRestrictionApplied ? 1 : 0.75 }}
        >
          <Tables />
        </Grid>

        {editable && <FormControls />}
      </Form>
    </Grid>
  ));

const ContentManagementStatus = createView()
  .props({ contentAllowedStatus: $contentAllowedStatus })
  .view(({ contentAllowedStatus }) => {
    if (contentAllowedStatus === ContentRestrictionStatus.Pending) {
      return (
        <CardHeader
          title='Content management'
          subheader={<Skeleton width={50} variant='text' />}
        />
      );
    }

    return (
      <CardHeader
        title='Content management'
        subheader={
          contentAllowedStatus === ContentRestrictionStatus.Allowed
            ? 'All content allowed'
            : 'Content restrictions applied'
        }
      />
    );
  });

const RoleSelect = createView()
  .props({
    editable: $canEdit,
    canApplyAdminRolesEdit: $canApplyAdminRolesEdit
  })
  .map(() => ({
    field: useField(form.fields.role),
    id: useId(),
    labelId: useId()
  }))
  .view(({ id, labelId, field, editable, canApplyAdminRolesEdit }) =>
    editable ? (
      <FormControl key='editable' sx={{ width: 150 }}>
        <InputLabel id={labelId}>Role</InputLabel>

        <Select
          labelId={labelId}
          id={id}
          value={field.value}
          label='Role'
          onChange={e => field.onChange(e.target.value as Role)}
          onBlur={() => field.onBlur()}
        >
          {roles.map(label => {
            const isAdminRole =
              label === Role.Admin || label === Role.SuperAdmin;

            return (
              <MenuItem
                key={label}
                value={label}
                disabled={isAdminRole && !canApplyAdminRolesEdit}
              >
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    ) : (
      <TextField
        key='readOnly'
        id={id}
        value={field.value}
        label='Role'
        sx={{ width: 150 }}
        InputProps={{ readOnly: true }}
      />
    )
  );

const Tables = createView()
  .props({
    onEditFinish: editFinished.prepend(trimPayload),
    onEditStart: editStarted.prepend(trimPayload),
    onRowChange: rowEdited,
    onTabChange: tabChanged.prepend((newValue: string) => +newValue),
    isFunctionTab: $isFunctionTab,
    tab: $currentTab,
    disabled: some({ stores: [$submitting, $editing], predicate: true }),
    editable: $canEdit
  })
  .map(({ isFunctionTab, editable }) => {
    const contentRestrictions = {
      market: useField(form.fields.marketAccess).value,
      function: useField(form.fields.functionAccess).value
    };

    return {
      columns: editable ? columns : nonEditableColumns,

      field: {
        rows: isFunctionTab
          ? contentRestrictions.function
          : contentRestrictions.market
      }
    };
  })
  .view(
    ({
      field,
      tab,
      columns,
      disabled,
      onTabChange,
      onRowChange,
      onEditStart,
      onEditFinish
    }) => (
      <Grid container flexDirection='column' flex={1} gap={1}>
        <Tabs
          variant='fullWidth'
          value={tab}
          onChange={(_, tab) => onTabChange(tab)}
          disabled={disabled}
        >
          {tabs.map((label, index) => (
            <Tab key={label} label={label} value={index} disabled={disabled} />
          ))}
        </Tabs>

        <DataGrid
          key={tabs[tab]}
          columns={columns}
          rows={field.rows}
          hideFooter
          processRowUpdate={row => {
            onRowChange(row);

            return row;
          }}
          onCellEditStart={onEditStart}
          onCellEditStop={onEditFinish}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Grid>
    )
  );

const tabs = ['Function', 'Market'];

const columns: GridColumns<DirectoryContentRestriction> = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    editable: false,
    flex: 1
  },
  {
    field: 'allowedRead',
    headerName: 'Read allowed',
    type: 'boolean',
    minWidth: 125,
    editable: true
  },
  {
    field: 'allowedWrite',
    headerName: 'Write allowed',
    type: 'boolean',
    minWidth: 125,
    editable: true
  }
];

const nonEditableColumns = columns.map(column => ({
  ...column,
  editable: false
}));

const FormControls = createView()
  .props({ editing: $editing, loading: $submitting })
  .view(({ editing, loading }) => (
    <Grid container component={CardContent} justifyContent='space-between'>
      <Button
        type='reset'
        disabled={editing}
        variant='contained'
        color='error'
        sx={{ width: 250 }}
      >
        Reset
      </Button>

      <LoadingButton
        type='submit'
        disabled={editing}
        loading={loading}
        variant='contained'
        color='success'
        sx={{ width: 250 }}
      >
        Submit
      </LoadingButton>
    </Grid>
  ));

export { ManageRoleAccess };
