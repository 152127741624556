import {
  combine,
  createEvent,
  createStore,
  merge,
  restore,
  sample
} from 'effector';

import { combineEvents, pending } from 'patronum';

import { $$session } from '@entities/session';

import { Role, sortRoles, userGroupsPriority } from '@entities/user';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { updateAttributesFx, updateRoleFx } from './api';

import { ContentRestrictionStatus, rolesAllContentAllowed } from './config';

import type {
  DirectoryContentRestriction,
  ManageRoleAccessFormType
} from './types';

const reset = createEvent();

const $email = createStore('');
const $firstName = createStore('');
const $lastName = createStore('');
const $groups = createStore<string[]>([]);

const $canEdit = combine(
  $$session.$user,
  $email,
  $groups,
  (user, targetEmail, groups) => {
    if (!user) return false;

    if (user.data.email === targetEmail) return false;

    const mainUserGroupIndex = userGroupsPriority.indexOf(
      sortRoles(groups.filter(Boolean) as Role[])[0]
    );

    const sessionGroupIndex = userGroupsPriority.indexOf(
      sortRoles(user.data.groups.filter(Boolean) as Role[])[0]
    );

    if (mainUserGroupIndex === -1) return true;

    if (sessionGroupIndex === 0) return true;

    return mainUserGroupIndex > sessionGroupIndex;
  }
);

const $canApplyAdminRolesEdit = combine(
  $canEdit,
  $$session.$user,
  (canEdit, user) =>
    user && canEdit && user.data.groups.includes(Role.SuperAdmin)
);

const form = createForm<ManageRoleAccessFormType>({
  fields: {
    role: {
      init: Role.Custom
    },
    functionAccess: {
      init: []
    },
    marketAccess: {
      init: []
    }
  },

  resetOn: reset,

  blurValidatePreset: true
});

const tabChanged = createEvent<number>();
const $currentTab = restore(tabChanged, 0);
const $isFunctionTab = $currentTab.map(tabIndex => tabIndex === 0);

const { rowEdited } = group('table row edited', () => {
  const rowEdited = createEvent<DirectoryContentRestriction>();

  sample({
    clock: rowEdited,
    source: form.$values,
    filter: $isFunctionTab,
    fn: ({ functionAccess }, newRow) =>
      functionAccess.map(directory =>
        directory.id === newRow.id ? newRow : directory
      ),
    target: form.fields.functionAccess.set
  });

  sample({
    clock: rowEdited,
    source: form.$values,
    filter: $isFunctionTab.map(is => !is),
    fn: ({ marketAccess }, newRow) =>
      marketAccess.map(directory =>
        directory.id === newRow.id ? newRow : directory
      ),
    target: form.fields.marketAccess.set
  });

  return { rowEdited };
});

const editStarted = createEvent();
const editFinished = createEvent();

const $editing = createStore(false)
  .on(editStarted, () => true)
  .reset(editFinished);

const $contentAllowedStatus = form.fields.role.$value.map(role => {
  if (!role) return ContentRestrictionStatus.Pending;

  if (rolesAllContentAllowed.includes(role))
    return ContentRestrictionStatus.Allowed;

  return ContentRestrictionStatus.Applied;
});

const { dataUpdated } = group('submit', () => {
  sample({
    clock: form.formValidated,
    source: { email: $email, firstName: $firstName, lastName: $lastName },
    fn: ({ email, firstName, lastName }, form) => ({
      email,
      firstName,
      lastName,
      form
    }),
    target: updateAttributesFx
  });

  sample({
    clock: form.formValidated,
    source: { email: $email, groups: $groups },
    fn: ({ email, groups }, { role }) => ({
      email,
      groups,
      role
    }),
    target: updateRoleFx
  });

  notification({
    clock: combineEvents({
      events: { roles: updateRoleFx.done, attributes: updateRoleFx.done },
      reset: form.formValidated
    }),
    message: 'Successfully updated',
    mode: 'success'
  });

  notification({
    clock: combineEvents({
      events: { roles: updateRoleFx.fail, attributes: updateAttributesFx.fail },
      reset: form.formValidated
    }),
    message: 'Error happen while update',
    mode: 'error',
    http: true
  });

  notification({
    clock: merge([
      combineEvents({
        events: {
          roles: updateRoleFx.done,
          attributes: updateAttributesFx.fail
        },
        reset: form.formValidated
      }),
      combineEvents({
        events: {
          roles: updateRoleFx.fail,
          attributes: updateAttributesFx.done
        },
        reset: form.formValidated
      })
    ]),
    message: 'Only partial updated',
    mode: 'error',
    http: true
  });

  return {
    dataUpdated: combineEvents({
      events: {
        roles: updateRoleFx.finally,
        attributes: updateAttributesFx.finally
      },
      reset: form.formValidated
    })
  };
});

const $submitting = pending({
  effects: [updateRoleFx, updateAttributesFx],
  of: 'some'
});

export {
  form,
  $editing,
  $currentTab,
  $isFunctionTab,
  $canApplyAdminRolesEdit,
  $contentAllowedStatus,
  $email,
  $firstName,
  $lastName,
  $groups,
  $canEdit,
  $submitting,
  rowEdited,
  editFinished,
  editStarted,
  tabChanged,
  dataUpdated
};
