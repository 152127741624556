import type { EffectParams } from 'effector';

import { attach, createEffect } from 'effector';

import type { Role } from '@entities/user';

import { api } from '@shared/api';

import { userGroupsToAdd } from './config';

import type { ManageRoleAccessFormType } from './types';

const updateAttributesFx = attach({
  mapParams: ({
    form: { functionAccess, marketAccess },
    email,
    firstName,
    lastName
  }: {
    form: ManageRoleAccessFormType;
    email: string;
    firstName: string;
    lastName: string;
  }): EffectParams<typeof api.users.updateUserAttributes> => ({
    email,

    data: {
      firstName,
      lastName,

      customAttributes: {
        functionsRead: functionAccess.filter(d => d.allowedRead).map(d => d.id),
        functionsWrite: functionAccess
          .filter(d => d.allowedWrite)
          .map(d => d.id),
        marketsRead: marketAccess.filter(d => d.allowedRead).map(d => d.id),
        marketsWrite: marketAccess.filter(d => d.allowedWrite).map(d => d.id)
      }
    }
  }),
  effect: api.users.updateUserAttributes
});

const updateRoleFx = createEffect(
  async ({
    email,
    groups,
    role
  }: {
    email: string;
    groups: string[];
    role: Role;
  }) => {
    if (groups.includes(role)) {
      return Promise.all(
        groups
          .filter(group => group !== role)
          .map(group =>
            api.usergroups.removeUserFromGroup({ email, usergroup: group })
          )
      );
    }

    await Promise.all(
      groups.map(group =>
        api.usergroups.removeUserFromGroup({ email, usergroup: group })
      )
    );

    if (userGroupsToAdd.includes(role)) {
      await api.usergroups.addUserToGroup({ email, usergroup: role });
    }
  }
);

export { updateRoleFx, updateAttributesFx };
