import type { TextFieldProps } from '@mui/material';

import { TextField } from '@mui/material';

import type { Field } from 'effector-forms';

import { useField } from 'effector-forms';

import { useFieldError } from '@shared/form/hooks';

import { createView } from '@shared/lib/view';

type TextProps = Omit<
  TextFieldProps,
  'name' | 'value' | 'error' | 'helperText' | 'onChange' | 'onBlur'
> & {
  field: Field<string>;
};

const Text = createView<TextProps>()
  .map(({ field }) => {
    const { name, value, onChange, onBlur } = useField(field);
    const { isError, errorText } = useFieldError(field);

    return { isError, errorText, value, name, onChange, onBlur };
  })
  .view(({ isError, field, errorText, onBlur, onChange, ...props }) => (
    <TextField
      error={isError}
      helperText={errorText}
      onChange={e => onChange(e.target.value)}
      onBlur={() => onBlur()}
      {...props}
    />
  ));

export { Text };

export type { TextProps };
