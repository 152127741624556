import { Grid, Step, StepLabel, Stepper } from '@mui/material';

import { Outlet } from 'react-router';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

import { displaySteps } from './config';

import { $activeStep, page } from './model';

const SetupMFALayout = createView()
  .props({ activeStep: $activeStep })
  .enter(page.mounted)
  .exit(page.unmounted)
  .view(({ activeStep }) => (
    <PageCenterLayout
      size={700}
      props={{ inner: { flexDirection: 'column', gap: 3 } }}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {displaySteps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container alignItems='center' justifyContent='center'>
        <Outlet />
      </Grid>
    </PageCenterLayout>
  ));

export { SetupMFALayout };
