import { loginRoutes } from './login.routes';

import { mfaRoutes } from './mfa.routes';

import { signUpRoutes } from './sign-up.routes';

const routes = {
  auth: {
    login: loginRoutes,
    signUp: signUpRoutes,
    mfa: mfaRoutes
  },
  users: {
    home: () => '/users',
    profile: (email = ':email') => `/users/${email}`
  }
};

export { routes };
