import { LoadingButton } from '@mui/lab';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide
} from '@mui/material';

import type { TransitionProps } from '@mui/material/transitions';

import type { ReactElement, Ref } from 'react';

import { forwardRef } from 'react';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import {
  $isStatusConfirmed,
  $open,
  $resetting,
  confirmClicked,
  dialogClosed,
  resetClicked
} from './model';

const ResetMFAButton = createView()
  .props({
    open: $open,
    loading: $resetting,
    disabled: $isStatusConfirmed.map(is => !is),
    onClick: resetClicked.prepend(trimPayload),
    onClose: dialogClosed.prepend(trimPayload),
    onConfirm: confirmClicked.prepend(trimPayload)
  })
  .view(({ loading, disabled, open, onClick, onClose, onConfirm }) => (
    <>
      <Button variant='contained' disabled={disabled} onClick={onClick}>
        Reset MFA
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
        keepMounted
      >
        <DialogTitle textAlign='center'>Are you sure?</DialogTitle>

        <DialogActions>
          <Button size='large' onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            color='primary'
            size='large'
            onClick={onConfirm}
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  ));

const Transition = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => (
    <Slide direction='up' ref={ref} {...props}>
      {props.children}
    </Slide>
  )
);

export { ResetMFAButton };
