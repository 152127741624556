import { Typography } from '@mui/material';

import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ChangePasswordPage,
  ConfirmLoginMfaPage,
  LoginPage
} from '@pages/auth/login';

import { ConfirmMFAPage, SetupMFALayout, SetupMFAPage } from '@pages/auth/mfa';

import {
  ConfirmEmailPage,
  ConfirmEmailTimeoutPage,
  RegisterPage,
  SignUpLayout
} from '@pages/auth/sign-up';

import { UserPage, UsersPage } from '@pages/users';

import { $$session } from '@entities/session';

import { routes } from '@shared/config';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

const Pages = createView()
  .props({
    isAuthenticated: $$session.$isAuthenticated,
    canUseAdminPanel: $$session.$canViewUI
  })
  .view(({ isAuthenticated, canUseAdminPanel }) => {
    if (!isAuthenticated) {
      return (
        <Routes>
          <Route path={routes.auth.login()} element={<LoginPage />} />

          <Route
            path={routes.auth.login.mfa()}
            element={<ConfirmLoginMfaPage />}
          />

          <Route
            path={routes.auth.login.changePassword()}
            element={<ChangePasswordPage />}
          />

          <Route path={routes.auth.signUp()} element={<SignUpLayout />}>
            <Route
              path={routes.auth.signUp.register()}
              element={<RegisterPage />}
            />

            <Route
              path={routes.auth.signUp.confirmEmail()}
              element={<ConfirmEmailPage />}
            />

            <Route
              path={routes.auth.signUp.confirmEmailAfterTimeout()}
              element={<ConfirmEmailTimeoutPage />}
            />
          </Route>

          <Route path={routes.auth.mfa()} element={<SetupMFALayout />}>
            <Route path={routes.auth.mfa.setup()} element={<SetupMFAPage />} />

            <Route
              path={routes.auth.mfa.confirm()}
              element={<ConfirmMFAPage />}
            />
          </Route>

          <Route path='*' element={<Navigate to={routes.auth.login()} />} />
        </Routes>
      );
    }

    if (canUseAdminPanel) {
      return (
        <Routes>
          <Route path={routes.users.home()} element={<UsersPage />} />

          <Route path={routes.users.profile()} element={<UserPage />} />

          <Route path='*' element={<Navigate to={routes.users.home()} />} />
        </Routes>
      );
    }

    return (
      <PageCenterLayout>
        <Typography display='inline-block' align='center' width='100%'>
          You don`t have access to content
        </Typography>
      </PageCenterLayout>
    );
  });

export { Pages };
