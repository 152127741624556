import { userGroupsPriority } from '../config';

import type { Role } from '../types';

const sortRoles = (roles: Role[]) =>
  roles.slice().sort((a, b) => {
    const aPriority = userGroupsPriority.indexOf(a);
    const bPriority = userGroupsPriority.indexOf(b);

    return aPriority - bPriority;
  });

export { sortRoles };
