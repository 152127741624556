import { LoadingButton } from '@mui/lab';

import { Grid } from '@mui/material';

import { Field, Form } from '@shared/form';

import { createView } from '@shared/lib/view';

import { form, resetPasswordFx } from './model';

const $loading = resetPasswordFx.pending;
const $$fields = form.fields;

const ChangePasswordForm = createView()
  .props({ loading: $loading })

  .view(({ loading }) => (
    <Form
      use={form}
      renderForm={({ onSubmit, children }) => (
        <Grid
          container
          item
          component='form'
          flexDirection='column'
          gap={3}
          onSubmit={onSubmit}
        >
          {children}
        </Grid>
      )}
    >
      <Field.Text
        field={$$fields.email}
        type='email'
        autoComplete='email'
        label='Email'
        disabled
      />

      <Field.Password field={$$fields.password} label='New password' />

      <Grid container gap={1.5} justifyContent='center'>
        <LoadingButton
          type='submit'
          variant='contained'
          loading={loading}
          fullWidth
        >
          Change password
        </LoadingButton>
      </Grid>
    </Form>
  ));

export { ChangePasswordForm };
