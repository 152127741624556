import { attach, createEvent, createStore, restore, sample } from 'effector';

import type { TableUser } from '@entities/user';

import { UserAdapter } from '@entities/user';

import { api } from '@shared/api';

import { routes } from '@shared/config';

import { $$router } from '@shared/history';

import { group } from '@shared/lib/effector-group';

import { createPage } from '@shared/lib/units';

import { LIMIT } from './config';

const page = createPage();

const getUsersFx = attach({ effect: api.users.getUsers });
const getInitialUsersFx = attach({ effect: getUsersFx });
const getMoreUsersFx = attach({ effect: getUsersFx });

const loadMoreClicked = createEvent();
const navigateToUserClicked = createEvent<TableUser>();

const $nextToken = restore(
  getUsersFx.doneData.map(({ data }) => data.nextToken ?? ''),
  ''
);

const $users = createStore<TableUser[]>([]);

group('load', () => {
  sample({
    clock: page.mounted,
    fn: () => ({ limit: String(LIMIT) }),
    target: getInitialUsersFx
  });

  $users.on(getInitialUsersFx.doneData, (_, { data }) =>
    data.users.map(UserAdapter.toTableUser)
  );
});

group('load more', () => {
  sample({
    clock: loadMoreClicked,
    source: $nextToken,
    fn: nextToken =>
      nextToken
        ? { nextToken, limit: String(LIMIT) }
        : { limit: String(LIMIT) },
    target: getMoreUsersFx
  });

  $users.on(getMoreUsersFx.doneData, (users, { data }) => [
    ...users,
    ...data.users.map(UserAdapter.toTableUser)
  ]);
});

group('navigateToUserClicked', () => {
  sample({
    clock: navigateToUserClicked,
    fn: ({ email }) => routes.users.profile(email),
    target: $$router.pushFx
  });
});

group('reset', () => {
  $users.reset(page.unmounted);
  $nextToken.reset(page.unmounted);
});

const $loading = getUsersFx.pending;

const $loadMoreDisabled = $nextToken.map(token => !token);

export {
  page,
  $users,
  $loading,
  $loadMoreDisabled,
  loadMoreClicked,
  navigateToUserClicked
};
