import { SetupMFAForm } from '@features/auth/setup-mfa';

import { createView } from '@shared/lib/view';

import { confirmBackClicked } from '../model';

const ConfirmMFAPage = createView()
  .props({ onBackClick: confirmBackClicked })
  .view(({ onBackClick }) => (
    <SetupMFAForm
      onGoBackClick={onBackClick}
      disableHorizontalPaddings
      disabledBottomPaddings
    />
  ));

export { ConfirmMFAPage };
