import { createEvent, createStore, sample } from 'effector';

import { notification } from '@shared/notification';

import { resetMFAFx } from './api';

const resetClicked = createEvent();

const $email = createStore('');
const $status = createStore('');

const $isStatusConfirmed = $status.map(status => status === 'CONFIRMED');

const confirmClicked = createEvent();
const dialogClosed = createEvent();

const $open = createStore(false)
  .on(resetClicked, () => true)
  .reset(dialogClosed);

sample({
  clock: confirmClicked,
  source: $email,
  target: resetMFAFx
});

notification({
  clock: resetMFAFx.done,
  message: 'MFA reseted',
  mode: 'success'
});

notification({
  clock: resetMFAFx.fail,
  message: 'Something happen while resetting MFA',
  mode: 'error',
  http: true
});

$open.reset(resetMFAFx.done);

const $resetting = resetMFAFx.pending;

export {
  $resetting,
  $email,
  $status,
  $open,
  $isStatusConfirmed,
  resetClicked,
  confirmClicked,
  dialogClosed
};
