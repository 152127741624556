import { createEvent, createStore } from 'effector';

const createPage = <T = void>() => {
  const mounted = createEvent<T>();
  const unmounted = createEvent();

  const $isMounted = createStore(false)
    .on(mounted, () => true)
    .reset(unmounted);

  return { mounted, unmounted, $isMounted };
};

export { createPage };
