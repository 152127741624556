import { ArrowForward } from '@mui/icons-material';

import { Box, Button } from '@mui/material';

import type { GridColumns, GridSlotsComponent } from '@mui/x-data-grid';

import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';

import { useMemo } from 'react';

import type { TableUser } from '@entities/user';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { userTableColumns } from './config';

import {
  $loading,
  $loadMoreDisabled,
  $users,
  loadMoreClicked,
  navigateToUserClicked,
  page
} from './model';

const UsersPage = createView()
  .props({
    users: $users,
    loading: $loading,
    onNavigateToUserClick: navigateToUserClicked
  })
  .enter(page.mounted)
  .exit(page.unmounted)
  .map(({ onNavigateToUserClick }) => {
    const columns = useMemo(
      (): GridColumns<TableUser> => [
        ...userTableColumns,
        {
          type: 'actions',
          field: 'ACTIONS',
          getActions: ({ row }) => [
            <GridActionsCellItem
              key='1'
              label={`Go to ${row.email}`}
              icon={<ArrowForward />}
              onClick={() => onNavigateToUserClick(row)}
            />
          ]
        }
      ],
      []
    );

    return { columns };
  })
  .view(({ users, loading, columns }) => (
    <DataGrid
      columns={columns}
      rows={users}
      loading={loading}
      hideFooter
      components={components}
      initialState={initialState}
    />
  ));

const CustomGridToolbar = createView().view(() => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />

    <GridToolbarFilterButton />

    <GridToolbarDensitySelector />

    <GridToolbarLoadMore />

    <Box ml='auto'>
      <GridToolbarQuickFilter />
    </Box>
  </GridToolbarContainer>
));

const GridToolbarLoadMore = createView()
  .props({
    disabled: $loadMoreDisabled,
    onClick: loadMoreClicked.prepend(trimPayload)
  })
  .view(({ disabled, onClick }) => (
    <Button size='small' disabled={disabled} onClick={onClick}>
      Load More
    </Button>
  ));

const components: Partial<GridSlotsComponent> = {
  Toolbar: CustomGridToolbar
};

const initialState = {
  columns: {
    columnVisibilityModel: {
      id: false
    }
  }
};

export { UsersPage };
