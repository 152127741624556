import { createEvent, merge, sample } from 'effector';

import { sessionLib } from '@entities/session';

import type { SignupBodyDto } from '@shared/api';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { resendEmailCodeFx, signUpFx } from './api';

import { isUserAlreadyExistError } from './lib';

const reset = createEvent();

const form = createForm<SignupBodyDto>({
  fields: {
    email: {
      init: '',

      rules: [sessionLib.validation.email]
    },
    firstName: {
      init: '',

      rules: [sessionLib.validation.firstName]
    },
    lastName: {
      init: '',

      rules: [sessionLib.validation.lastName]
    },
    password: {
      init: '',

      rules: [sessionLib.validation.password]
    }
  },

  resetOn: reset,

  blurValidatePreset: true
});

group('submit', () => {
  sample({
    clock: form.formValidated,
    target: signUpFx
  });

  notification({
    clock: signUpFx.failData.filter({
      fn: error => !isUserAlreadyExistError(error)
    }),

    message: 'Error while register. Use different credentials',

    mode: 'error',

    http: true
  });

  sample({
    clock: signUpFx.failData,
    source: form.$values,
    filter: (_, error) => isUserAlreadyExistError(error),
    fn: ({ email }) => ({ email }),
    target: resendEmailCodeFx
  });

  notification({
    clock: merge([signUpFx.done, resendEmailCodeFx.done]),

    message: 'Successfully registered',

    mode: 'success'
  });
});

export { signUpFx, resendEmailCodeFx, form, reset };
