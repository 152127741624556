import type { UUID } from '@shared/types';

enum Role {
  SuperAdmin = 'Super_Admin',
  Admin = 'Admin',
  WriterFull = 'Write_All',
  ReaderFull = 'Read_All',
  Custom = 'Custom'
}

type TableUser = {
  id: UUID;
  email: string;
  firstName: string;
  lastName: string;

  role: string;

  emailConfirmed: boolean;
  mfaEnabled: boolean;
};

export type { TableUser };

export { Role };
