import type { GridColumns } from '@mui/x-data-grid';

import type { TableUser } from '@entities/user';

const userTableColumns: GridColumns<TableUser> = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'role',
    headerName: 'Role',
    type: 'string',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    type: 'string',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    type: 'string',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'emailConfirmed',
    headerName: 'Email confirmed',
    type: 'boolean',
    width: 175
  },
  {
    field: 'mfaEnabled',
    headerName: 'MFA enabled',
    type: 'boolean',
    width: 175
  }
];

const LIMIT = 60;

export { userTableColumns, LIMIT };
