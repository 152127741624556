import type {
  Directory,
  DirectoryContentRestriction
} from '@features/manage-role-access';

class Adapter {
  public static toDirectoryContentRestriction = (
    directories: Directory[],
    { read, write }: { read: Directory['id'][]; write: Directory['id'][] }
  ): DirectoryContentRestriction[] =>
    directories.map(({ id, name }) => ({
      id,
      name,
      allowedRead: read.length ? read.includes(id) : false,
      allowedWrite: write.length ? write.includes(id) : false
    }));
}

export { Adapter };
